
import { defineComponent, computed } from "vue";
import CommunityForm from "@/components/community/CommunityForm.vue";
import PageGreetingVue from "@/components/PageGreeting.vue";
import { useStore, AppActionTypes } from "@/store";
import { loadData } from "@/helpers";

export default defineComponent({
    components: {
        "community-form": CommunityForm,
        "page-greeting": PageGreetingVue
    },
    setup() {
        // Page greeting
        const title = "Community 👨‍👩‍👦";
        const message =
            "Use our contact form below - we value your feedback and would love to hear from you!";

        // Get the Community Images
        const store = useStore();
        const communityImages = computed(() => store.state.communityImages);

        loadData(store, () => {
            if (communityImages.value.length === 0) {
                store.dispatch(AppActionTypes.getCommunityImages);
            }
        });

        return { title, message, communityImages };
    }
});
