import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-8 offset-lg-2" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_greeting = _resolveComponent("page-greeting")!
  const _component_community_form = _resolveComponent("community-form")!
  const _component_content_card = _resolveComponent("content-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_page_greeting, {
        title: _ctx.title,
        message: _ctx.message,
        "center-content": true
      }, null, 8, ["title", "message"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_content_card, { "use-default-theme": true }, {
          default: _withCtx(() => [
            _createVNode(_component_community_form)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.communityImages, (image) => {
        return (_openBlock(), _createElementBlock("div", {
          key: image,
          class: "col-xl-3 col-md-4 col-6 p-3"
        }, [
          _createElementVNode("div", {
            class: "image-size",
            style: _normalizeStyle({
                    backgroundImage: `url(${image.imageUrl})`,
                    backgroundSize: 'cover'
                })
          }, null, 4)
        ]))
      }), 128))
    ])
  ], 64))
}