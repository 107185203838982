import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "row align-items-start mt-2 mb-3" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "onUpdate:modelValue", "value"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  key: 0,
  class: "fw-bold fst-italic"
}
const _hoisted_6 = { class: "row mb-3" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "row mb-3" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "row mb-0" }
const _hoisted_11 = { class: "col text-end" }
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_indicator = _resolveComponent("loading-indicator")!
  const _component_val_form_input = _resolveComponent("val-form-input")!
  const _component_upload = _resolveComponent("upload")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_loading_indicator, { key: 0 }))
    : (_openBlock(), _createBlock(_component_v_form, {
        key: 1,
        "initial-values": _ctx.model,
        "validation-schema": _ctx.rules,
        onSubmit: _ctx.onSubmit
      }, {
        default: _withCtx(({ isSubmitting }) => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reasonOptions, (reason) => {
              return (_openBlock(), _createElementBlock("div", {
                key: reason.value,
                class: "col mb-3 md-md-0 d-flex flex-column align-items-center justify-content-center text-center reason"
              }, [
                _createElementVNode("label", {
                  type: "radio",
                  class: _normalizeClass(["btn btn-secondary btn-circle btn-xl", {
                        'reason-active': _ctx.model.reason === reason.value
                    }]),
                  for: reason.value
                }, [
                  _createVNode(_component_val_form_input, {
                    modelValue: _ctx.model.reason,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.model.reason) = $event),
                    rules: _ctx.rules.reason,
                    name: _ctx.rules.reason.propertyName
                  }, {
                    default: _withCtx(({ field }) => [
                      _withDirectives(_createElementVNode("input", _mergeProps({
                        id: reason.value,
                        "onUpdate:modelValue": ($event: any) => ((_ctx.model.reason) = $event)
                      }, field, {
                        class: "btn-check",
                        name: "reason",
                        value: reason.value,
                        type: "radio"
                      }), null, 16, _hoisted_3), [
                        [_vModelRadio, _ctx.model.reason]
                      ])
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "rules", "name"]),
                  _createElementVNode("img", {
                    src: _ctx.communityButtonBaseUrl + reason.src,
                    alt: reason.text,
                    class: "img-button"
                  }, null, 8, _hoisted_4)
                ], 10, _hoisted_2),
                _createElementVNode("div", {
                  class: _normalizeClass(["mt-2 font-raleway reason-label", {
                        'font-weight-bold': _ctx.model.reason === reason.value
                    }])
                }, _toDisplayString(reason.text), 3)
              ]))
            }), 128))
          ]),
          (_ctx.model.reason)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.model.reason === 'SubmitYourEbkProject')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, " Please include the child’s first name, last initial, and age in the comments field so that we can recognize them if their artwork is selected to be featured. "))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_val_form_input, {
                      modelValue: _ctx.model.comments,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.comments) = $event)),
                      rules: _ctx.rules.comments,
                      type: "textarea"
                    }, null, 8, ["modelValue", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_val_form_input, {
                      modelValue: _ctx.files,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.files) = $event)),
                      rules: _ctx.rules.fileUrls,
                      label: "Attachments",
                      name: "fileUrls"
                    }, {
                      default: _withCtx(({ valid, inValid }) => [
                        _createVNode(_component_upload, {
                          id: "file-upload",
                          class: _normalizeClass({
                                'is-valid': valid,
                                'is-invalid': inValid
                            }),
                          batch: true,
                          files: _ctx.files,
                          multiple: true,
                          "auto-upload": true,
                          "remove-method": "delete",
                          "remove-field": "FileNames",
                          "save-url": _ctx.apiFileUrl,
                          "save-headers": _ctx.uploadHeaders,
                          "remove-headers": _ctx.uploadHeaders,
                          "remove-url": _ctx.apiFileUrl,
                          "aria-invalid": inValid,
                          "aria-label": _ctx.rules.fileUrls.displayName,
                          "aria-required": 
                                _ctx.rules.fileUrls.required ? 'true' : 'false'
                            ,
                          onBeforeupload: _ctx.beforeUpload,
                          onAdd: _ctx.uploadFile,
                          onBeforeremove: _ctx.beforeRemove,
                          onRemove: _ctx.removeFile,
                          onStatuschange: _ctx.onStatusChange
                        }, null, 8, ["class", "files", "save-url", "save-headers", "remove-headers", "remove-url", "aria-invalid", "aria-label", "aria-required", "onBeforeupload", "onAdd", "onBeforeremove", "onRemove", "onStatuschange"])
                      ]),
                      _: 1
                    }, 8, ["modelValue", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("button", {
                      disabled: isSubmitting,
                      class: "btn btn-primary font-weight-bold",
                      type: "submit"
                    }, " Submit Message ", 8, _hoisted_12)
                  ])
                ])
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["initial-values", "validation-schema", "onSubmit"]))
}