import { CommunityReasonOptionModel } from "@/models";

export const reasonOptions: CommunityReasonOptionModel[] = [
    {
        value: "SubmitYourEbkProject",
        text: "Submit Your EverBright Kids Project",
        src: "community-submit.png"
    },
    {
        value: "NeedHelp",
        text: "I Need Help",
        src: "community-help.png"
    },
    {
        value: "SuggestArticlesFeatures",
        text: "Suggest Articles/Features",
        src: "community-suggest.png"
    }
];
