
import { defineComponent, computed, ref, reactive } from "vue";
import { CommunityModel } from "@/models";
import { useStore, AppActionTypes } from "@/store";
import { reasonOptions } from "./reasonOptions";

import {
    getModelStateFromResponse,
    hasErrors,
    ValFormInput
} from "@elite/validation";
import { Form, FormActions } from "vee-validate";
import {
    UploadOnAddEvent,
    UploadOnRemoveEvent,
    Upload,
    UploadOnStatusChangeEvent,
    UploadFileInfo,
    UploadOnBeforeUploadEvent,
    UploadOnBeforeRemoveEvent
} from "@progress/kendo-vue-upload";
import swal from "sweetalert";

export default defineComponent({
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput,
        "upload": Upload
    },
    setup() {
        const model: CommunityModel = reactive({
            comments: "",
            reason: undefined,
            fileUrls: [],
            guid: undefined
        });

        const { state, getters, dispatch } = useStore();

        const files = ref<UploadFileInfo[]>([]);

        // Submit Form
        const onSubmit = async (
            values: CommunityModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            if (model.fileUrls && model.fileUrls.length > 0) {
                values.fileUrls = model.fileUrls;
            }

            const response = await dispatch(
                AppActionTypes.submitCommunityRequest,
                values
            );

            if (hasErrors(response)) {
                getModelStateFromResponse(response, actions);
                return;
            }

            // Clear form
            model.reason = undefined;
            model.comments = "";
            model.fileUrls = [];
            files.value = [];
            actions.resetForm();

            // Show Success Message
            await swal({
                text: "Your message has been sent!",
                icon: "success"
            });
        };

        // Before Upload File
        const beforeUpload = (event: UploadOnBeforeUploadEvent): void => {
            event.additionalData.guid = model.guid || "";
        };

        // Upload File
        const uploadFile = (event: UploadOnAddEvent): void => {
            files.value = event.newState;
        };

        // Before Remove File
        const beforeRemove = (event: UploadOnBeforeRemoveEvent): void => {
            event.additionalData.Guid = model.guid;
            event.additionalData.FileUrls = model.fileUrls;
        };

        // Remove new image from upload component
        const removeFile = async (
            event: UploadOnRemoveEvent
        ): Promise<void> => {
            files.value = event.newState;
            model.fileUrls = event.response?.response;
        };

        const onStatusChange = (event: UploadOnStatusChangeEvent): void => {
            files.value = event.newState;

            // Loop through the file URL's in the response
            if (event.response?.response.fileUrls) {
                event.response?.response.fileUrls.forEach((item: string) => {
                    // Add attachment file names to the model
                    model.fileUrls?.push(item);
                });
            }

            /**
             * Set the value of the GUID used to prepend the file names on upload
             * (All files associated to this email will be in the same folder in storage)
             */
            if (event.response?.response.guid) {
                model.guid = event.response?.response.guid;
            }
        };

        return {
            loading: computed(() => getters.loading),
            model,
            onSubmit,
            rules: computed(() =>
                getters.getModelValidationRules("communityModel")
            ),
            // Reason options
            reasonOptions,
            communityButtonBaseUrl: `${state.cdnBaseUrl}/assets/images/community-form-buttons/`,
            // File Upload
            files,
            uploadFile,
            removeFile,
            onStatusChange,
            uploadHeaders: computed(() => ({
                Authorization: `Bearer ${state.accessToken}`
            })),
            beforeUpload,
            beforeRemove,
            apiFileUrl: computed(() => `${state.apiBaseUrl}/api/communityfile`)
        };
    }
});
